import React from 'react';

import Layout from 'layout/index';

import SEO from 'components/seo/index';

import Initiativepage from 'components/initiative/initiativepage';


const TrackaSapling = () => {
  return (
    <>
      <SEO title='Forests By Heartfulness | track a Sapling' />
      <Layout>
        <section id='trackaSapling'>
          <Initiativepage />
        </section>
      </Layout>
    </>
  );
};

export default TrackaSapling;